import axios from 'axios';
import Cookies from 'quasar/src/plugins/Cookies.js';;
import { StatusCode } from './httpStatusCode'; 

import router from "@/router/index.js"; // I assume you have router module

function handleResponse(response){
    return response;
}


function handleError({response}){
    const { status } = response;
    if(status == StatusCode.UNAUTHORIZED){
        router.push({name: 'login'});
        return null;
    }

    if(status == StatusCode.NOT_FOUND){
        router.push({name: 'notFound'});
        return null;
    } 
    return response;
}



export function postRequest(endpoint, data, config = null) {
    let body = null;
    if(Cookies.has("user")){
        body =   { token: Cookies.get("user").message  , ...data};
    }else{
        if(Cookies.has("token")){
            body =   { token: Cookies.get("token") , ...data};
        }else{
            body = { ...data };
        }
    }
    return axios.post(endpoint, JSON.stringify(body), config ? config : {}).then(handleResponse).catch(handleError);
}

export function postRequestFormData(endpoint, datos){
    const formData = new FormData();
    if(Cookies.get("user")){
        formData.append("token", Cookies.get("user").message);
    }
    const keys = Object.keys(datos);
    keys.map(key =>{
        formData.append(key, datos[key]);
    })
    return axios.post(endpoint, formData, {
        headers: {
            "Content-Type": "multipart/form-data", // Indicar que el contenido es form-data
        },
    }).then(handleResponse)
}
